import React, { ReactElement } from "react";
import { DateTime } from "luxon";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarStar,
  faCheck,
  faShip,
  faTimes,
  faWarehouse,
} from "@fortawesome/pro-solid-svg-icons";

import { Layouts } from "react-grid-layout";
import { LayoutItem } from "./features/control-room";
import { Step } from "react-joyride";
import { TypedGridColDef } from "@components/ModuleOverviewTable/ModuleOverviewTable";
import { ApiVersion } from "@src/paths";

export type AttrType =
  | "text"
  | "date"
  | "datetime"
  | "number"
  | "nonnegativeNumber"
  | "decimal"
  | "nonnegativeDecimal"
  | "list"
  | "callOffStatus"
  | "positionStatus"
  | "currency";
export type Status =
  | "UPCOMING"
  | "IN_TRANSIT"
  | "WAREHOUSING"
  | "DELIVERED"
  | "CANCELED"
  | "DROPSHIPPING"
  | "OPEN"
  | "COMPLETED";
export type CallOffStatus = Status;
export type ContractStatus = Status;
export type ContractPositionStatus = Status;
export type StatusDetails = {
  status: Status;
  label: string;
  icon: ReactElement;
};
export type NotificationColors = "light" | "success" | "warning" | "error" | "info";
export type UserRole = "admin" | "data_editor" | "member";

export const statusDetails: Record<Status, StatusDetails> = {
  OPEN: {
    status: "OPEN",
    label: "Open",
    icon: <FontAwesomeIcon color="dark" icon={faCalendarStar} size="xs" />,
  },
  COMPLETED: {
    status: "COMPLETED",
    label: "Completed",
    icon: <FontAwesomeIcon color="dark" icon={faCheck} size="xs" />,
  },
  UPCOMING: {
    status: "UPCOMING",
    label: "Upcoming",
    icon: <FontAwesomeIcon color="dark" icon={faCalendarStar} size="xs" />,
  },
  IN_TRANSIT: {
    status: "IN_TRANSIT",
    label: "In Transit",
    icon: <FontAwesomeIcon color="dark" icon={faShip} size="xs" />,
  },
  DELIVERED: {
    status: "DELIVERED",
    label: "Delivered",
    icon: <FontAwesomeIcon color="dark" icon={faCheck} size="xs" />,
  },
  WAREHOUSING: {
    status: "WAREHOUSING",
    label: "Warehousing",
    icon: <FontAwesomeIcon color="dark" icon={faWarehouse} size="xs" />,
  },
  CANCELED: {
    status: "CANCELED",
    label: "Canceled",
    icon: <FontAwesomeIcon color="dark" icon={faTimes} size="xs" />,
  },
  DROPSHIPPING: {
    status: "DROPSHIPPING",
    label: "Dropshipping",
    icon: <FontAwesomeIcon color="dark" icon={faShip} size="xs" />,
  },
};

export type UserConfig = {
  currentCompanyId?: number;
  favoriteCompanyIds?: number[];
  controlRoomLayouts?: Layouts;
  controlRoomItems?: Array<Partial<LayoutItem<any>>>;
  notes?: string | { uuid: string; content: string }[];
};

export type Company = {
  id: number;
  name: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  salesRepEmail: string;
  salesRepName: string;
  vatId: string;
  isDistributor: boolean;
  members: CompanyMembership[];
  customers: Pick<Company, "id" | "name">[];
  distributors: Pick<Company, "id" | "name">[];
  invitations: Invitation[];
};

export type CompanyMembership = {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  displayName: string;
  companyId: number;
  companyName: string;
  companyStreet: string;
  companyZipCode: string;
  companyCity: string;
  companyCountry: string;
  companySalesRepName: string;
  companySalesRepEmail: string;
  companyIsDistributor: boolean;
  companyHasActiveMembers: boolean;
  viewConfigKey: string;
  role: "admin";
};

export const ROLES = ["member", "data_editor", "admin", "distributor_member"] as const;

export const roleNames: Record<(typeof ROLES)[number], string> = {
  member: "Member",
  data_editor: "Data Editor",
  distributor_member: "Distributor Member",
  admin: "Admin",
};

export type Invitation = {
  id: number;
  company: number;
  email: string;
  role: (typeof ROLES)[number];
  invitedBy: string;
  isAccepted: boolean;
};

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  companies: CompanyMembership[];
  nCompanyMemberships: number;
  locale?: string;
  isSuperuser?: boolean;
  config?: UserConfig;
  firstLoginAt?: string;
  termsAcceptedAt?: string;
};

export type Comment = {
  id: number;
  message: string;
  author: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type DjangoRestResult = {
  count: number;
  next: number;
  previous: number;
  results: any[];
};

export type File = {
  id?: string;
  file?: string;
  fileName: string;
  url?: string;
};

export type Notification = {
  id: string;
  unread: boolean;
  prio: number;
  actor: {
    avatarUrl: string;
    firstName: string;
    lastName: string;
  };
  verb: string;
  target: string;
  timestamp: string;
  description: string;
  href: string;
};

export type NotificationSettingsChannelPreferences = {
  email: boolean;
  web: boolean;
};

export type NotificationSettingsEvents = {
  comment?: NotificationSettingsChannelPreferences;
  mention?: NotificationSettingsChannelPreferences;
};

export type NotificationSettings = {
  global?: NotificationSettingsEvents;
  contracts?: NotificationSettingsEvents;
  callOffs?: NotificationSettingsEvents;
  warehousing?: NotificationSettingsEvents;
  upcomingVessels?: NotificationSettingsEvents;
  materialInTransit?: NotificationSettingsEvents;
};

export type AlertSettings = {
  title: string;
  content: string;
  notDismissable?: boolean;
  autoHideDuration?: number | null;
  action?: (close: () => void) => React.ComponentType<any> | React.ReactElement;
  onClose?: () => void;
};

export type Alert = {
  settings: AlertSettings;
  color: NotificationColors;
  icon: IconDefinition;
  isOpen: boolean;
  dateTime: string;
  notifyInfo: (settings: AlertSettings) => void;
  notifySuccess: (settings: AlertSettings) => void;
  notifyWarning: (settings: AlertSettings) => void;
  notifyError: (settings: AlertSettings) => void;
  close: () => void;
};

export type ContentFrameProps = {
  headerComponent?: ReactElement | null;
  setHeaderComponent: ((component: ReactElement) => void) | null;
};

export type ObjectClass = "Contract" | "ContractPosition" | "CallOff" | "CallOffPosition";

export type ObjectField<T extends ObjectClass> = T extends "Contract"
  ? keyof Contract
  : T extends "ContractPosition"
    ? keyof ContractPosition
    : T extends "CallOff"
      ? keyof CallOff
      : T extends "CallOffPosition"
        ? keyof CallOffPosition
        : never;

export type UIModule =
  | "Contracts"
  | "MaterialInTransit"
  | "Warehousing"
  | "Upcoming"
  | "CallOffs"
  | "Products";

export interface ObjectLink<T> {
  objectClass: ObjectClass;
  fieldRef: keyof T;
}

export type DetailFieldDef<T> = {
  field: keyof T;
  label: string;
  type?: AttrType;
  valueGetter?: (value: any) => string;
  readonly?: boolean;
  link?: ObjectLink<T>;
};

export type WithTimeTracking = {
  createdTs?: DateTime;
  modifiedTs?: DateTime;
};

export type WithComments = {
  comments?: Comment[];
};

export type WithFiles = {
  files?: File[];
};

export type WithHref = {
  genericRef?: string;
  href?: string;
};

export type WithCompanies = {
  distributor?: Pick<Company, "id" | "name">;
  purchaser?: Pick<Company, "id" | "name">;
};

export type WithReference = {
  distributorReference?: string;
  customerReference?: string;
};

export type Product = {
  simple?: string;
  countryOfOrigin?: string;
  nameDe?: string;
  nameEn?: string;
  purity?: string;
  purityMax?: number;
  purityMin?: number;
  sizeMax?: number;
  sizeMin?: number;
  sizeUnit?: string;
  size?: string;
};

export type WithProduct = {
  productSimple?: string;
  productCountryOfOrigin?: string;
  productNameDe?: string;
  productNameEn?: string;
  productPackagingDe?: string;
  productPackagingEn?: string;
  productPurity?: string;
  productPurityMax?: number;
  productPurityMin?: number;
  productSize?: string;
  productSizeMax?: number;
  productSizeMin?: number;
  productSizeUnit?: string;
};

export type Contract = WithTimeTracking &
  WithComments &
  WithFiles &
  WithHref &
  WithCompanies &
  WithReference & {
    id?: number;
    prms?: string;
    contractPositions?: ContractPosition[];
    callOffs?: CallOff[];
    purchaseDate?: DateTime;
    totalPurchasePrice?: number;
    productsDe?: string;
    productsEn?: string;
    simpleProducts?: string;
    packagingEn?: string;
    incoterms2020?: string;
    incoterms?: string;
    incotermsCity?: string;
    paymentConditionCode?: string;
    paymentConditionDe?: string;
    paymentConditionEn?: string;
    sourceModifiedTs?: DateTime;
    totalAmount?: string;
    totalAmountLeftFrac?: number;
    totalAmountShipped?: number;
    totalAmountShippedFrac?: number;
    status?: ContractStatus;
  };

export const contractPositionStatusDetails: {
  status: ContractPositionStatus;
  label: string;
  icon?: ReactElement;
}[] = [
  {
    status: "UPCOMING",
    label: "Upcoming",
    icon: <FontAwesomeIcon color="dark" icon={faCalendarStar} size="xs" />,
  },
  {
    status: "IN_TRANSIT",
    label: "In Transit",
    icon: <FontAwesomeIcon color="dark" icon={faShip} size="xs" />,
  },
  {
    status: "WAREHOUSING",
    label: "Warehousing",
    icon: <FontAwesomeIcon color="dark" icon={faWarehouse} size="xs" />,
  },
];

export type ContractPosition = WithTimeTracking &
  WithProduct &
  WithComments &
  WithFiles &
  WithCompanies &
  WithHref & {
    id?: number;
    amount?: number;
    amountLeft?: number;
    amountLeftCalculated?: number;
    amountShippedCalculated?: number;
    amountUnit?: string;
    callOffs?: CallOff[];
    contract?: number;
    contractPosition?: string;
    contractCustomerReference?: string;
    contractDistributorReference?: string;
    displayContractCustomerReference?: string;
    purchasePrice?: number;
    incoterms2020?: string;
    packagingEn?: string;
    callOffPackagingEn?: string;
    prm?: string;
    notes?: string;
    bulkSpaceAvailable?: number;
    bagSpaceAvailable?: number;
    imo?: string;
    vesselName?: string;
    lotBargeVessel?: string;
    bargeLocation?: string;
    month?: string;
    departureOrigin?: DateTime;
    laycans?: DateTime;
    etaNola?: DateTime;
    etaWarehouse?: DateTime;
    warehouse?: string;
    warhouseLocation?: string;
    sourceModifiedTs?: DateTime;
    totalPrice?: number;
    totalPriceCurrency?: string;
    unitPrice?: number;
    unitPriceCurrency?: string;
    status?: ContractPositionStatus;
  };

export type CallOff = WithTimeTracking &
  WithComments &
  WithFiles &
  WithHref &
  WithCompanies &
  WithReference & {
    id?: number;
    callOffPositions?: CallOffPosition[];
    commissionDate?: DateTime;
    companyName?: string;
    container?: string;
    contract?: number;
    contractDistributorReference?: string;
    deliveryId?: string;
    deliveryMethod?: string;
    destCity?: string;
    destCountry?: string;
    destUnlocode?: string;
    eta?: DateTime;
    etaNote?: string;
    etd?: DateTime;
    etdNote?: string;
    goodsOutputDate?: DateTime;
    imo?: string;
    incoterms?: string;
    incotermsCity?: string;
    loaded?: boolean;
    loadingDate?: DateTime;
    originCity?: string;
    originCountry?: string;
    originUnlocode?: string;
    paymentConditionCode?: string;
    paymentConditionDe?: string;
    paymentConditionEn?: string;
    productsDe?: string;
    productsEn?: string;
    simpleProducts?: string;
    shipClass?: string;
    shipCountry?: string;
    shipName?: string;
    status?: CallOffStatus;
    statusByDate?: CallOffStatus;
    totalAmount?: number;
    pricePerLot?: number;
    transportStatusCode?: string;
    sourceModifiedTs?: DateTime;
  };

export type CallOffPosition = WithTimeTracking &
  WithProduct & {
    id?: number;
    callOff?: number;
    callOffPosition?: string;
    contractPosition?: number;
    amount?: number;
    amountUnit?: string;
    unitPrice?: number;
    unitPriceCurrency?: string;
    totalPrice?: number;
    totalPriceCurrency?: string;
    status?: CallOffStatus;
    statusByDate?: CallOffStatus;
    shipName?: string;
    packagingEn?: string;
    warehouse?: string;
  };

type FieldDefs<T> = {
  detailView: DetailFieldDef<T>[];
  overviewList: TypedGridColDef<T>[];
  childList: TypedGridColDef<T>[];
};

export type ObjectViewSettings<T> = {
  titleSingular: string;
  titlePlural: string;
  statusField: "status" | "statusByDate" | null;
  dataModifiable: boolean;
  availableStatus: Status[];
  disableDetailView?: boolean;
  documents?: {
    comingSoon?: boolean;
    hide?: boolean;
  };
  fields: {
    default: FieldDefs<T>;
    byStatus?: {
      [status in Status]?: FieldDefs<T>;
    };
  };
};

export type TourStep = Step & { nextStepOnClick?: boolean; lazyTarget?: boolean };

export interface ViewSettings {
  apiVersion: ApiVersion;
  tourSteps?: TourStep[];
  contract: ObjectViewSettings<Contract>;
  contractPosition: ObjectViewSettings<ContractPosition>;
  callOff: ObjectViewSettings<CallOff>;
  callOffPosition: ObjectViewSettings<CallOffPosition>;
  manualObjectCreation: boolean;
  hasControlRoom: boolean;
  requestQuoteButton: boolean;
  uiModules: UIModule[];
  availableObjectClasses: ObjectClass[];
}

export type NavigationElement =
  | "signIn"
  | "confirmEmail"
  | "settings"
  | "resetPassword"
  | "resetPasswordEnterNew"
  | "controlRoom"
  | "contractOverview"
  | "productOverview"
  | "contractDetail"
  | "contractPositionDetail"
  | "callOffOverview"
  | "callOffDetail"
  | "warehousingOverview"
  | "warehousingDetail"
  | "materialInTransitOverview"
  | "materialInTransitDetail"
  | "upcomingVesselsOverview"
  | "upcomingVesselDetail"
  | "divider1"
  | "newContract"
  | "newContractPosition"
  | "newRequestQuote";

export const possibleViewKeyDependentLocalStorageKeys = [
  "ContractTable",
  "CallOffTable",
  "CallOffDetailPositionsTable",
  "MaterialInTransitTable",
  "UpcomingVesselsTable",
  "WarehousingTable",
];
export type LocalStorageKey = (typeof possibleViewKeyDependentLocalStorageKeys)[number];

export interface Breadcrumb {
  label: string;
  url?: string;
}

export type ConditionalObjectClass<T extends ObjectClass> = T extends "Contract"
  ? Contract
  : T extends "ContractPosition"
    ? ContractPosition
    : T extends "CallOff"
      ? CallOff
      : T extends "CallOffPosition"
        ? CallOffPosition
        : never;

export interface FilterSpec<F extends "statusByDate" | "status" = any> {
  field: F;
  value: F extends "statusByDate" | "status" ? Status : any;
}

export interface ProductReorderingSpecs {
  id?: string;
  posId?: string;
  name?: string;
  amount?: string;
  productQuality?: string;
  productSize?: string;
  packaging?: string;
}
