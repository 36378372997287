import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Skeleton, Tooltip, Zoom } from "@mui/material";
import Cookies from "js-cookie";
import { delay } from "lodash";

import UploadedFile from "@components/UploadedFile/UploadedFile";
import SusDropzone from "@components/SusDropzone/SusDropzone";
import SusWidget from "@src/templates/SusWidget";
import SusBox from "@components/SusBox";
import SusTypography from "@components/SusTypography";
import useAlerts from "@hooks/useAlerts";
import dashboardAxios from "@src/api";
import { MAX_UPLOAD_FILESIZE } from "@src/constants";
import { File } from "@src/types";
import { useDashboardState } from "@src/app.state";
import { styled } from "@mui/material/styles";

interface IDropzoneAreaProps {
  url: string;
  fileAttachments: File[];
  loading?: boolean;
  comingSoon?: boolean;
}

const DropzoneArea: React.FC<IDropzoneAreaProps> = ({
  fileAttachments,
  url,
  loading,
  comingSoon,
}) => {
  const { currentCompany } = useDashboardState();
  const csrfToken = Cookies.get("csrftoken");
  const [displayFileAttachments, setDisplayFileAttachments] = useState<File[]>(fileAttachments);
  const alerts = useAlerts();

  useEffect(() => {
    setDisplayFileAttachments(fileAttachments);
  }, [fileAttachments]);

  const filesUploadedHandler = (files: File[]) => {
    delay(() => {
      setDisplayFileAttachments([...displayFileAttachments, ...files]);
    }, 1000);
  };

  const onRemoveFile = useCallback(
    (removedFile: File) => {
      if (!removedFile?.url) {
        return;
      }
      dashboardAxios
        .delete(removedFile.url)
        .then(() => {
          alerts.notifySuccess({
            title: "Success",
            content: "File deleted successfully.",
            autoHideDuration: 5000,
          });
          setDisplayFileAttachments(
            displayFileAttachments.filter((file) => file.id !== removedFile.id)
          );
        })
        .catch((error) => {
          console.log(error);
          let message = "Sorry, something went wrong. Please try again later.";
          if (error?.response?.status === 403) {
            message = "Sorry, you do not have permission to delete this file.";
          }
          alerts.notifyError({
            title: "Error",
            content: message,
            autoHideDuration: 5000,
          });
        });
    },
    [displayFileAttachments]
  );

  const hasWriteAccess = ["admin", "data_editor", "distributor_member"].includes(
    currentCompany?.role || ""
  );
  const fileList = useMemo(
    () =>
      (displayFileAttachments || []).map((file: File) => (
        <Grid
          key={file.id}
          item
          xs={6}
          sm={4}
          sx={{
            maxWidth: "220px !important",
          }}
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={107}
              sx={{ borderRadius: "13px" }}
            />
          ) : !!file ? (
            <>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={107}
                  sx={{ borderRadius: "13px" }}
                />
              ) : (
                <UploadedFile
                  key={file.id}
                  file={file}
                  onRemoveFile={hasWriteAccess ? onRemoveFile : null}
                />
              )}
            </>
          ) : null}
        </Grid>
      )),
    [displayFileAttachments, loading, hasWriteAccess]
  );

  const StyledGridOverlay = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginTop: "-1.5rem",
    marginLeft: "5px",
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <SusBox sx={{ mt: 1 }}>No documents available.</SusBox>
      </StyledGridOverlay>
    );
  }

  return (
    <SusWidget title={"Documents"} loading={loading} className={"product-documents-card"}>
      <Grid
        direction="row"
        spacing={0.5}
        container
        alignItems="left"
        justifyContent="left"
        sx={{
          position: "relativ",
        }}
      >
        {comingSoon && (
          <Tooltip title="This feature will be available soon." TransitionComponent={Zoom}>
            <SusBox
              sx={{
                position: "absolute",
                width: "100%",
                height: "calc(100% - 90px)",
                background: "rgba(255,255,255,0.50)",
                textAlign: "center",
                zIndex: "1",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SusTypography
                variant="headline"
                fontWeight="bold"
                textTransform="capitalize"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "top",
                  transform: "rotate(350deg)",
                  marginTop: "30px",
                }}
              >
                Coming Soon
              </SusTypography>
            </SusBox>
          </Tooltip>
        )}
        {fileList}
        {hasWriteAccess ? (
          <Grid
            item
            xs={6}
            sm={4}
            sx={{
              maxWidth: "220px !important",
            }}
          >
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={107}
                sx={{ borderRadius: "13px" }}
              />
            ) : (
              <SusDropzone
                onFilesUploaded={(files) => filesUploadedHandler(files)}
                loading={loading}
                options={{
                  paramName: "files",
                  params: () => ({
                    file_name: "file",
                  }),
                  acceptedFiles: "application/pdf",
                  dictDefaultMessage: "Upload File",
                  uploadMultiple: true,
                  maxFilesize: MAX_UPLOAD_FILESIZE,
                  createImageThumbnails: false,
                  url,
                  headers: {
                    "X-CSRFToken": csrfToken || "",
                  },
                  withCredentials: true,
                  maxFiles: comingSoon ? 0 : 1,
                }}
              />
            )}
          </Grid>
        ) : fileList.length == 0 ? (
          <CustomNoRowsOverlay />
        ) : null}
      </Grid>
    </SusWidget>
  );
};
export default DropzoneArea;
