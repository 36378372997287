import { useEffect, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone, { DropzoneFile } from "dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUp } from "@fortawesome/pro-solid-svg-icons";

// Custom styles.js
import useStyles from "./styles";
import { camelizeKeys } from "humps";
import { Skeleton } from "@mui/material";
import useAlerts from "@hooks/useAlerts";
import SusDropzoneRoot from "@components/SusDropzone/SusDropzoneRoot";
import SusBox from "@components/SusBox";
import { File } from "@src/types";

interface ISusDropZoneProps {
  options: Dropzone.DropzoneOptions;
  onFilesUploaded?: (files: File[]) => void;
  onFileRemoved?: (file: string) => void;
  loading?: boolean;
}

function SusDropzone({ options, onFilesUploaded, loading }: ISusDropZoneProps) {
  const dropzoneRef = useRef();
  const [error, setError] = useState("");
  const alerts = useAlerts();

  useEffect(() => {
    Dropzone.autoDiscover = false;

    if (dropzoneRef?.current !== undefined) {
      const dropzone = new Dropzone(dropzoneRef.current, { ...options });
      dropzone.on("success", (files: any, response: any) => {
        response = camelizeKeys(response);
        setError("");
        onFilesUploaded?.(response);
      });
      dropzone.on("successmultiple", (files: any, response: any) => {
        response = camelizeKeys(response);
        setError("");
        onFilesUploaded?.(response);
      });
      dropzone.on("error", (file: DropzoneFile, message: string | Error) => {
        if (message && typeof message !== "string" && "message" in message && message?.message) {
          setError("true");
          alerts.notifyWarning({
            title: "Something went wrong",
            content: "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
        if (message && typeof message === "string") {
          setError("true");
          alerts.notifyWarning({
            title: "Something went wrong",
            content: "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
      });
      dropzone.on("errormultiple", (files: DropzoneFile[], message: string | Error) => {
        if (message && typeof message !== "string" && "message" in message && message?.message) {
          setError("true");
          alerts.notifyWarning({
            title: "Something went wrong",
            content: "Sorry, something went wrong. Please try again later.",
            autoHideDuration: 5000,
          });
        }
        if (message && typeof message === "string") {
          setError("true");
          alerts.notifyWarning({
            title: "Wrong file type or file too large",
            content:
              'You can only upload file that have the type ".pdf". The maximum file size is 5 MB.',
            autoHideDuration: 50000,
          });
        }
      });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    return () => removeDropzone();
  }, [options, dropzoneRef]);
  const classes = useStyles();

  return (
    <>
      <SusDropzoneRoot
        ref={dropzoneRef}
        className={`dropzone form-control ${classes.dropzone} ${error && "dz-error"}`}
      >
        {loading ? (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={107}
            sx={{ borderRadius: "13px" }}
          />
        ) : (
          <SusBox display="flex" flexDirection="column">
            <FontAwesomeIcon
              className="icon"
              icon={faSquareArrowUp}
              style={{ pointerEvents: "none" }}
            />
            <SusBox className="fallback">
              <FontAwesomeIcon
                className="icon"
                icon={faSquareArrowUp}
                style={{ pointerEvents: "none" }}
              />
              <SusBox component="input" name="file" type="file" multiple />
            </SusBox>
          </SusBox>
        )}
      </SusDropzoneRoot>
    </>
  );
}

// Typechecking props for the SusDropzone
SusDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
};

export default SusDropzone;
