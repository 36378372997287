import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import useStyles from "./styles";
import SusBox from "@components/SusBox";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface SusDocumentViewProps {
  url: string;
}

const SusDocumentView: React.FC<React.PropsWithChildren<SusDocumentViewProps>> = ({ url }) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  interface onDocumentLoadSuccessProps {
    numPages: number;
  }

  function onDocumentLoadSuccess({ numPages: _numPages }: onDocumentLoadSuccessProps) {
    setNumPages(_numPages);
  }

  const classes = useStyles();

  const loadingProgress = (
    <SusBox
      p={2}
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "70vh", width: "100%" }}
    >
      <CircularProgress />
    </SusBox>
  );

  return (
    <SusBox className={classes.document}>
      <Document
        file={{ url }}
        options={{
          withCredentials: true,
        }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={loadingProgress}
      >
        {numPages != null
          ? Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))
          : null}
      </Document>
    </SusBox>
  );
};

export default SusDocumentView;
